<template>
  <div>
    <breadCrumb :title="appName +' - Terms and Conditions'" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left">
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>
            <h1>{{ appName }} Terms and Conditions</h1>
            <p>
              Welcome to {{ appName }}. By using {{ appName }}, you agree to be bound by these terms and conditions.
            </p>
            <p>
              1. Definitions
            </p>
            <ul>
              <li>
                "{{ appName }}" refers to the website operated by [company name], with the URL [website address].
              </li>
              <li>
                "User" refers to any individual or entity that uses {{ appName }}.
              </li>
              <li>
                "Seller" refers to any individual or entity that sells goods or services on {{ appName }}.
              </li>
            </ul>
            <p>
              2. Terms of Use
            </p>
            <ul>
              <li>
                You must be at least 18 years of age to use {{ appName }}.
              </li>
              <li>
                You may not use {{ appName }} for any illegal or unlawful purpose.
              </li>
              <li>
                You may not post any illegal, defamatory, threatening, or offensive content.
              </li>
              <li>
                You may not interfere with the normal operation of {{ appName }} in any way.
              </li>
            </ul>
            <p>
              3. Seller Terms
            </p>
            <ul>
              <li>
                Sellers must provide accurate and truthful information about their products.
              </li>
              <li>
                Sellers must ship products promptly and provide excellent customer service.
              </li>
              <li>
                Sellers must comply with all applicable laws and regulations.
              </li>
            </ul>
            <p>
              4. Payments and Returns
            </p>
            <ul>
              <li>
                {{ appName }} accepts a variety of payment methods.
              </li>
              <li>
                {{ appName }} has a return policy, which can be found on the {{ appName }} return policy page.
              </li>
            </ul>
            <p>
              5. Privacy Policy
            </p>
            <ul>
              <li>
                {{ appName }} respects users' privacy. For more information about {{ appName }}'s privacy policy, please visit the {{ appName }} privacy policy page.
              </li>
            </ul>
            <p>
              6. Limitation of Liability
            </p>
            <ul>
              <li>
                {{ appName }} is not liable for any loss or damage to users or third parties arising from the use of {{ appName }}.
              </li>
            </ul>
            <p>
              7. Governing Law
            </p>
            <ul>
              <li>
                These terms and conditions are governed by the laws of the People's Republic of China.
              </li>
            </ul>
            <p>
              8. Modifications
            </p>
            <ul>
              <li>
                {{ appName }} reserves the right to modify these terms and conditions at any time.
              </li>
            </ul>
            <p>
              9. Contact Information
            </p>
            <ul>
              <li>
                If you have any questions, please contact {{ appName }}'s customer service team.
              </li>
            </ul>
          </template>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getArticleLists } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: 'Terms and Conditions for Cross-Border Marketplace',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '5')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>